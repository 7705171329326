<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal } from 'bootstrap-vue'
export default {
  components: {
    BCard, BButton, BModal,
  },
  data() {
    return {
      pageArrayStock: [], // for Frontend Search
      pageArray: [],

      filterFirstname: '',
      filterLastname: '',
      filterUsername: '',
    }
  },
  async created() {
    await this.mountedData()
  },
  methods: {
    async mountedData() {

      // Clear Variables..
      this.editModal = false

      this.filterFirstname = ''
      this.filterLastname = ''
      this.filterUsername = ''

      // Get List
      await this.$http.get('user/list')
        .then(response => {
          this.pageArrayStock = this.pageArray = response.data
        }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
    },
    async filterNow() {
      this.pageArray = this.pageArrayStock.filter(x => x.userFirstname.toLocaleLowerCase().includes(this.filterFirstname.toLocaleLowerCase()) && x.userLastname.toLocaleLowerCase().includes(this.filterLastname.toLocaleLowerCase()) && x.userUsername.toLocaleLowerCase().includes(this.filterUsername.toLocaleLowerCase()))
    },
    routeUser(item) {
      this.$root.$router.push({
        path: `/user/${item.userFirstname}`,
        params: { errors: 'error' },
        query: { userId: item.userId }
      })
    }
  },
  watch: {
    filterFirstname: {
      handler() {
        this.filterNow()
      }
    },
    filterLastname: {
      handler() {
        this.filterNow()
      }
    },
    filterUsername: {
      handler() {
        this.filterNow()
      }
    }
  }
}
</script>

<template>
  <section class="pb-2">
    <div class="tb-card">
      <a class="tb-page-title">Registered Users ({{ pageArrayStock.length }})</a>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
          <tr>
            <th style="width: 5%"><input type="text" class="form-control" placeholder="#" disabled readonly></th>
            <th style="width: 20%"><input type="text" class="form-control" placeholder="First Name"
                v-model="filterFirstname"></th>
            <th style="width: 20%"><input type="text" class="form-control" placeholder="Last Name"
                v-model="filterLastname"></th>
            <th style="width: 20%"><input type="text" class="form-control" placeholder="Username"
                v-model="filterUsername"></th>
            <th style="width: 20%"><input type="text" class="form-control" placeholder="Create Date Time" disabled
                readonly></th>
            <th style="width: 15%"><input type="text" class="form-control" placeholder="Options" disabled readonly></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in pageArray">
            <td><a>{{ index + 1 }}</a></td>
            <td><a>{{ item.userFirstname }}</a></td>
            <td><a>{{ item.userLastname }}</a></td>
            <td><a>{{ item.userUsername }}</a></td>
            <td><a>{{ item.createdAt | moment('DD-MM-YYYY - HH:mm:ss') }}</a></td>
            <td>
              <button class="tb-button bg-success" @click="routeUser(item)">
                <span class="text-white">Detail</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>